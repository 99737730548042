import app from '@/main';
import EventService from "@/service/EventService";
import CompendiumService from "@/service/CompendiumService";

import { showToast, showErrorToast } from "@/helpers/actions/helpers";

class EventActions {
    items = [];
    menuItems= [];

    beforeConvert = null;
    afterConvert = null;

    beforeDelete = null;
    afterDelete = null;

    beforeDeleteFromBureaus = null;
    afterDeleteFromBureaus = null;

    confirm = app.config.globalProperties.$confirm;
    router = app.config.globalProperties.$router;
    store = app.config.globalProperties.$store;

    constructor(event, formats) {
        const items = [];

        items.push(
            {
                label: 'Перейти к заёмщику',
                icon: 'pi pi-fw pi-user',
                command: () => this.pushToBorrower(event.borrowerUuid),
                menuOnly: true
            },
            {
                label: 'Перейти к займу',
                icon: 'pi pi-fw pi-money-bill',
                command: () => this.pushToLoan(event.loanId),
                menuOnly: true
            },
            { separator: true, menuOnly: true }
        );

        if (
            formats !== undefined &&
            (formats.nbki !== CompendiumService.getValueByCode(this.store.state.compendiums.actualFormatVersions, 'nbki') ||
            formats.equ !== CompendiumService.getValueByCode(this.store.state.compendiums.actualFormatVersions, 'equ'))
        ) {
            items.push(
                {
                    label: 'Преобразовать в актуальный формат',
                    icon: 'pi pi-fw pi-qrcode',
                    command: () => this.convertToNewFormat(event),
                    viewOnly: true
                },
                { separator: true, viewOnly: true },
            );
        }

        items.push(
            {
                label: 'Удалить из сервиса',
                icon: 'pi pi-fw pi-trash',
                command: () => this.delete(event)
            },
            {
                label: 'Удалить из бюро',
                icon: 'pi pi-fw pi-trash',
                command: () => this.deleteFromBureaus(event)
            }
        );

        this.items = items.filter((item) => item.menuOnly !== true);
        this.menuItems = items.filter((item) => item.viewOnly !== true);
    }

    pushToBorrower(uuid) {
        const route = this.router.resolve({
            name: 'borrower_view',
            params: {
                uuid: uuid,
            }
        });
        window.open(route.href, '_blank');
    }

    pushToLoan(id) {
        const route = this.router.resolve({
            name: 'loan_view',
            params: {
                id
            }
        });
        window.open(route.href, '_blank');
    }

    convertToNewFormat(data) {
        this.confirm.require({
            header: "Подтвердите преобразование события",
            message: `Данные события №${data.id} будут обновлены под актуальные форматы. Продолжить?`,
            acceptIcon: 'pi pi-check',
            acceptLabel: 'Да',
            rejectLabel: 'Отмена',
            accept: () => {
                if (this.beforeConvert !== null) {
                    this.beforeConvert();
                }

                EventService.convertToNewFormat(data.id)
                    .then((res) => {
                        if (res.error === true) {
                            showToast(res.feedback, res.error);
                            return;
                        }

                        if (this.afterConvert !== null) {
                            this.afterConvert(res.feedback);
                        }
                    })
                    .catch((err) => {
                        console.error(err);
                        showErrorToast(err);
                    })
            }
        });
    }

    delete(data) {
        this.confirm.require({
            header: "Подтвердите удаление события",
            message: `Событие №${data.id} будет удалено из сервиса, информация о событии в бюро останется неизменной. Удалить событие?`,
            acceptIcon: 'pi pi-trash',
            acceptLabel: 'Удалить',
            rejectLabel: 'Отмена',
            accept: () => {
                if (this.beforeDelete !== null) {
                    this.beforeDelete();
                }

                EventService.deleteEvent(data.id)
                    .then(() => {
                        showToast(`Событие №${data.id} удалено`);
                    })
                    .catch((err) => {
                        showErrorToast(err);
                    })
                    .finally(() => {
                        if (this.afterDelete !== null) {
                            this.afterDelete();
                        }
                    })
            }
        });
    }

    deleteFromBureaus(data) {
        this.confirm.require({
            header: "Подтвердите удаление события",
            message: `Событие №${data.id} будет удалено из сервиса и всех бюро. Удалить событие?`,
            acceptIcon: 'pi pi-trash',
            acceptLabel: 'Удалить',
            rejectLabel: 'Отмена',
            accept: () => {
                if (this.beforeDeleteFromBureaus !== null) {
                    this.beforeDeleteFromBureaus();
                }

                EventService.deleteEventFromBureaus(data.id)
                    .then((res) => {
                        showToast(res.feedback, res.error);
                    })
                    .catch((err) => {
                        showErrorToast(err);
                    })
                    .finally(() => {
                        if (this.afterDeleteFromBureaus !== null) {
                            this.afterDeleteFromBureaus();
                        }
                    })
            }
        })
    }

    getItems() {
        return this.items;
    }

    getMenuItems() {
        return this.menuItems;
    }
}

export default EventActions;