<template>
  <Button
    :label="this.value"
    class="p-button-sm p-button-rounded p-button-primary mb-2"
    @click="copy"
  />
</template>

<script>
export default {
  name: 'ValueChip',
  props: {
    value: {
      type: [String, Number],
      required: true
    },
    toastTemplate: {
      type: String,
      default: () => 'Значение "{v}" скопировано'
    }
  },
  methods: {
    copy() {
      navigator.clipboard.writeText(this.value)
      this.$toast.add({
        severity: 'success',
        summary: 'Значение скопировано',
        detail: this.toastTemplate.replaceAll('{v}', this.value),
        life: 2000
      })
    }
  }
}
</script>