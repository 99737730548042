import rpc from "@/rpc";

class EventService {
    rpc

    constructor(rpc) {
        this.rpc = rpc
    }

    getEvent(eventId, eventDate, locale) {
        return this.rpc.execute('getEvent', {
            eventId: +eventId,
            eventDate: eventDate,
            locale: locale
        })
    }

    deleteEvent(eventId) {
        return this.rpc.execute('deleteEventById', {
            eventId: eventId
        })
    }

    deleteEventFromBureaus(eventId) {
        return this.rpc.execute('deleteEventFromBureaus', {
            eventId: eventId
        })
    }

    convertToNewFormat(eventId) {
        return this.rpc.execute('convertToNewFormat', {
            eventId: eventId
        })
    }
}

export default new EventService(rpc)