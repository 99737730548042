import app from '@/main';
import ResponseService from '@/service/ResponseService';

const toast = app.config.globalProperties.$toast;

const showToast = (message = undefined, isError = false) => {
    if (isError === true) {
        toast.add({
            severity: 'error',
            summary: 'Ошибка',
            detail: message ?? 'Ошибка выполнения действия',
            life: 5000
        });
        return;
    }

    toast.add({
        severity: 'success',
        summary: 'Действие выполнено',
        detail: message,
        life: 3000
    });
}

const showErrorToast = (err) => {
    ResponseService.handleErrorResponseWithoutForm(err, toast);
}

export {
    showToast,
    showErrorToast
}